import React, { useEffect, useRef, useState } from "react";
import BarcodeView from "./BarcodeView";
import { useSelector, useDispatch } from "react-redux";
import { settingSelector, StockSelector } from "../../../Redux/Reducers/Slices";
import { printLabel } from "../../../Redux/Actions/StockAction/StockAction";

const BarcodeContainer = ({
  barcodeError,
  barcodeId,
  handleBarcodeChange,
  handleKeyDown,
  handleFocusSearchInput,
  showSuggestionList,
  suggestionListLoading,
  suggestionList,
  setShowSuggestionList,
  setSuggestionListLoading,
  getSearchedProduct,
}) => {
  const [isLabelModal, setIsLabelModal] = useState(false);
  const { newStockInfo } = useSelector(StockSelector);
  const { systemSettingDetails } = useSelector(settingSelector);
  const componentRef = useRef(null);
  const dispatch = useDispatch();

  const handleOpenLabelModal = () => {
    setIsLabelModal(true);
  };
  const handleCloseLabelModal = () => {
    setIsLabelModal(false);
  };

  const handlePrintLabel = async () => {
    const payload = {
      barcodeId: newStockInfo?.barCodeId,
      price: newStockInfo?.newStocks[0]?.retailPrice,
      productName: newStockInfo?.productName,
    };
    await dispatch(printLabel(payload));
  };
  const handleClickOutside = (event) => {
    if (
      componentRef?.current &&
      !componentRef?.current.contains(event?.target)
    ) {
      setShowSuggestionList(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <BarcodeView
      {...{
        barcodeError,
        barcodeId,
        handleBarcodeChange,
        handleFocusSearchInput,
        handleKeyDown,
        handleOpenLabelModal,
        isLabelModal,
        handleCloseLabelModal,
        newStockInfo,
        componentRef,
        handlePrintLabel,
        systemSettingDetails,
        showSuggestionList,
        suggestionListLoading,
        suggestionList,
        setShowSuggestionList,
        setSuggestionListLoading,
        getSearchedProduct,
      }}
    />
  );
};

export default BarcodeContainer;
