import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import ProductsListView from "./ProductsListView";
import { useNavigate } from "react-router-dom";
import { STALE_TIME } from "../../../Constant/primitive";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../Utils/isEmpty/isEmpty";
import { useDebounce } from "../../../hooks/useDebounce";
import {
  getProduct,
  permissionSelector,
  settingSelector,
} from "../../../Redux/Reducers/Slices";
import {
  getProductData,
  deleteProductDataById,
  getBrand,
  getSuggestionProductName,
} from "../../../Redux/Actions";
import { CREATE_PRODUCT, PRODUCT_LIST } from "../../../Constant/routeConstant";
import { productAction } from "../../../Redux/Reducers/ProductReducers/ProductReducers";
import { printLabel } from "../../../Redux/Actions/StockAction/StockAction";

const ProductsListContainer = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    id: "",
    isDeleteModal: false,
  });
  const [barcodeModel, setBarcodeModel] = useState(false);
  const [productDetails, setProductDetails] = useState();
  const [filterSearch, setFilterSearch] = useState({});
  const [isStatus, setIsStatus] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [showSuggestionList, setShowSuggestionList] = useState(false);
  const [suggestionListLoading, setSuggestionListLoading] = useState(false);
  const { productData, total, suggestionList } = useSelector(
    (state) => state.Product
  );
  const listRef = useRef(null);
  const { myPermissions } = useSelector(permissionSelector);
  const payload = {};
  let { brandData } = useSelector(settingSelector);
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const handleChangeNewProduct = (name) => {
    sessionStorage.setItem("sidebarHeaderTitle", name);
    navigation(CREATE_PRODUCT);
  };

  const handleDeleteProduct = (productId) => {
    setDeleteModal({
      id: productId,
      isDeleteModal: true,
    });
  };

  const handleOpenBarcodeModel = (productDetails) => {
    setProductDetails(productDetails);
    setBarcodeModel(true);
  };
  const handleCloseBarcodeModel = () => {
    setBarcodeModel(false);
  };
  const handlePrintLabel = async () => {
    const payload = {
      barcodeId: productDetails?.barCodeId,
      price: productDetails?.newStocks[0]?.retailPrice,
      productName: productDetails?.productName,
    };
    await dispatch(printLabel(payload));
  };
  const handleChangeLimit = async (limit) => {
    setLimit(limit);
    setLoading(true);
    const response = await dispatch(
      getProductData(payload, page, limit, "others")
    );
    if (response?.status === 200) {
      dispatch(getProduct(response?.data));
    }
    setLoading(false);
  };

  const handleGetProduct = async (searchValue, page, limit) => {
    setLoading(true);
    const payload = {
      searchedKeyWord: searchValue,
    };
    const response = await dispatch(
      getProductData(payload, page, limit, "others")
    );
    if (response?.status === 200) {
      dispatch(getProduct(response?.data));
    }
    setLoading(false);
  };

  const handleFilterProduct = async (filterSearch, page, limit) => {
    setLoading(true);
    const response = await dispatch(
      getProductData(filterSearch, page, limit, "others")
    );
    if (response?.status === 200) {
      dispatch(getProduct(response?.data));
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetProduct(searchValue, page, limit);
    return () => {
      dispatch(productAction.suggestionList([]));
    };
  }, []);

  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setLimit(pageSize);
    handleGetProduct("", page, pageSize);
  };

  const handleDeleteConfirmModal = async () => {
    setIsStatus(true);
    await dispatch(deleteProductDataById(deleteModal?.id));
    setIsStatus(false);
    setDeleteModal({
      ...deleteModal,
      isDeleteModal: false,
    });
  };
  const handleDeleteCancelModal = () => {
    setDeleteModal({
      id: "",
      isDeleteModal: false,
    });
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchValue(value.trim());
    if (isEmpty(value)) {
      setShowSuggestionList(false);
      dispatch(productAction.suggestionList([]));
      if (productData.length < limit) {
        handleGetProduct("", page, limit);
      }
    } else {
      setShowSuggestionList(true);
      setSuggestionListLoading(true);
    }
  };

  const handleKeyDown = () => {};

  const handleViewProducts = () => {
    sessionStorage.setItem("sidebarHeaderTitle", "Product Details");
    sessionStorage.setItem("subTitle", "Product-List");
    sessionStorage.setItem("subTitleLink", PRODUCT_LIST);
  };
  const handleEditProducts = () => {
    sessionStorage.setItem("sidebarHeaderTitle", "Edit Product");
    sessionStorage.setItem("subTitle", "Product-List");
    sessionStorage.setItem("subTitleLink", PRODUCT_LIST);
  };

  const handleGetAllBrand = async (page, limit) => {
    const params = { page, limit };
    const response = await dispatch(getBrand(params));
    return response;
  };

  useQuery({
    queryKey: ["brand"],
    queryFn: () => handleGetAllBrand(1, 10),
    staleTime: STALE_TIME,
  });
  const handleSelectChange = (value, name) => {
    setFilterSearch((prev) => ({
      ...prev,
      [name?.toLowerCase()]: value,
    }));
  };
  brandData = brandData?.map((brand) => ({
    option: brand?.brandName,
    value: brand?.brandName,
  }));

  const handleFilterSearch = () => {
    if (isEmpty(filterSearch)) return;
    handleFilterProduct(filterSearch, 1, limit);
  };

  const handleProductListSort = async (_, __, columnData) => {
    const { columnKey, order } = columnData;

    const sortPayload = {
      searchKeyword: "",
      sortColumn: columnKey,
      ...(order && { sortOrder: order === "ascend" ? "asc" : "desc" }),
    };
    const response = await dispatch(
      getProductData(sortPayload, 1, 10, "others")
    );
    if (response?.status === 200) {
      dispatch(getProduct(response?.data));
    }
    return response;
  };

  const handleFocusSearchInput = () => {
    suggestionList.length > 0 && setShowSuggestionList(true);
  };

  const getSearchedProduct = (value) => {
    handleGetProduct(value, 1, 10);
  };
  const getProductSuggestions = async () => {
    await dispatch(getSuggestionProductName(searchValue));
    setSuggestionListLoading(false);
  };

  useDebounce(searchValue, getProductSuggestions);

  return (
    <ProductsListView
      {...{
        brandData,
        myPermissions,
        handleChangeNewProduct,
        handleDeleteProduct,
        barcodeModel,
        handleOpenBarcodeModel,
        handleCloseBarcodeModel,
        handlePrintLabel,
        productDetails,
        productData,
        setPage,
        handlePageChange,
        currentPage: page,
        total,
        limit,
        handleChangeLimit,
        deleteModal,
        handleDeleteConfirmModal,
        handleDeleteCancelModal,
        isStatus,
        loading,
        handleSearchChange,
        handleKeyDown,
        handleViewProducts,
        handleEditProducts,
        handleSelectChange,
        handleFilterSearch,
        filterSearch,
        handleProductListSort,
        showSuggestionList,
        handleFocusSearchInput,
        suggestionList,
        suggestionListLoading,
        setShowSuggestionList,
        getSearchedProduct,
        listRef,
      }}
    />
  );
};

export default ProductsListContainer;
