import React, { useState } from "react";
import { VegetablesFruitsCategoryTable } from "../../../Component";
import { DeleteModalComponent } from "../../../Component/Model";
import "./vegetablesFruitsList.scss";
import { ButtonComponent, ModalComponent } from "../../../CommonComponent";
import Barcode from "react-barcode";
import { isEmpty } from "../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { settingSelector } from "../../../Redux/Reducers/Slices";
import { printLabel } from "../../../Redux/Actions/StockAction/StockAction";

const VegetablesFruitsCategoryView = ({
  handleAddNewVegetablesFruitsCategory,
  handleDeleteModal,
  handleEditModal,
  isDeleteModal,
  handleCancelDeleteRecord,
  handleSaveDeleteRecord,
  vegFruitListInfo,
  isVegFruitListLoading,
  handleViewModal,
  myPermissions,
  handlePageChange,
  total,
  limit,
  handleSearchChange,
  currentPage,
  handleVegFruitListSort,
  brandData,
  loading,
  handleSelectChange,
  handleFilterSearch,
  filterSearch,
  handleKeyDown,
  showSuggestionList,
  handleFocusSearchInput,
  suggestionList,
  suggestionListLoading,
  setShowSuggestionList,
  getSearchedProduct,
  listRef,
}) => {
  const [barcodeModel, setBarcodeModel] = useState(false);
  const [productDetails, setProductDetails] = useState();
  const dispatch = useDispatch();
  const { systemSettingDetails } = useSelector(settingSelector);

  const handleOpenBarcodeModel = (productDetails) => {
    setProductDetails(productDetails);
    setBarcodeModel(true);
  };
  const handleCloseBarcodeModel = () => {
    setBarcodeModel(false);
  };
  const handlePrintLabel = async () => {
    const payload = {
      barcodeId: productDetails?.barCodeId,
      price: productDetails?.newStocks[0]?.retailPrice,
      productName: productDetails?.productName,
    };
    await dispatch(printLabel(payload));
  };
  const priceToLog = productDetails?.newStocks
    ? productDetails?.newStocks?.[0]?.retailPrice
    : productDetails?.VegAndFruitsPackages?.[0]?.retailPrice;
  return (
    <div className="Vegetables-main">
      <VegetablesFruitsCategoryTable
        {...{
          handleVegFruitListSort,
          handleAddNewVegetablesFruitsCategory,
          handleDeleteModal,
          handleOpenBarcodeModel,
          handleEditModal,
          vegFruitListInfo,
          isVegFruitListLoading,
          handleViewModal,
          myPermissions,
          handlePageChange,
          total,
          limit,
          handleSearchChange,
          currentPage,
          brandData,
          loading,
          handleSelectChange,
          handleFilterSearch,
          filterSearch,
          handleKeyDown,
          isSuggestionListVisible: true,
          showSuggestionList,
          handleFocusSearchInput,
          suggestionList,
          suggestionListLoading,
          setShowSuggestionList,
          getSearchedProduct,
          listRef,
        }}
      />
      {isDeleteModal?.isOpen && (
        <DeleteModalComponent
          {...{
            isModalOpen: isDeleteModal?.isOpen,
            handleCancelDeleteRecord,
            isDeleteModalLoading: isDeleteModal?.isLoading,
            handleSaveDeleteRecord,
            name: `Fruit or Vegetable`,
          }}
        />
      )}
      {barcodeModel && (
        <ModalComponent
          modalOpen={barcodeModel}
          modalTitle={"Barcode Label"}
          handleModalCancel={handleCloseBarcodeModel}
          modalClass={"barcode-label-modal"}
        >
          <div className="product-title">
            <span>{productDetails?.productName}</span>
          </div>
          <Barcode
            value={
              productDetails?.barCodeId
                ? productDetails?.barCodeId
                : productDetails?.VegAndFruitsPackages?.[0]?.packageBarCodeId
            }
            className="barcode"
          />
          {!isEmpty(productDetails?.newStocks) && (
            <div className="product-price">
              <span>
                {systemSettingDetails?.currency}
                {priceToLog ? priceToLog : <span>&nbsp;--</span>}/
                {productDetails?.unit?.baseUnit}
              </span>
            </div>
          )}
          <div className="barcode-modal-button-main">
            <ButtonComponent
              btnName={"Print"}
              btnClass={"barcode-modal-button"}
              handleClick={handlePrintLabel}
            />
          </div>
        </ModalComponent>
      )}
    </div>
  );
};

export default VegetablesFruitsCategoryView;
